import React, { Component } from 'react';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'

class Contact extends Component {

    constructor(props){

        super(props);
        if (props.contactInfo)
            this.state = props.contactInfo
        else
            this.state ={
                'name': '',
                'email':''
            };
    }
    componentDidMount(){
        if (this.props.onChange)
            this.props.onChange(this.state);
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state)
            return;

        if (this.props.onChange)
            this.props.onChange(this.state);
    }
    render() {
        return (
            <div className="mb-4">
                <form className="js-validate mt-5">

                    <div className="form-row">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-6 mb-4 mx-auto">
                            <div className="js-form-message text-left">
                                <label className="" htmlFor="name">Name</label>
                                <div className="input-group">
                                    <input type="text" onChange={this.onChange.bind(this)} value={this.state.name}
                                    className="form-control" name="name" id="form-name" placeholder="First name" aria-label="FirstName" required="What do we call you?" data-msg="Name must contain only letters" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-6 mx-auto">
                            <div className="js-form-message text-left">
                                <label className="" htmlFor="email">Email</label>
                                <div className="input-group">
                                    <MaskedInput onChange={this.onChange.bind(this)}  value={this.state.email}
                                     mask={emailMask} guide="False" name="email" className="form-control" placeholder="Email" aria-label="Email" required="We need a way to contact you" data-msg="Invalid Email" />
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        );
    }
}

export default Contact;