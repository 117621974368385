import React from 'react';
import BuyingWizard from './Wizard';
import Header from './Header';

function WizardFrame(props) {
  return (
    <div className="d-flex align-items-center position-relative height-lg-100vh">
      <Header></Header>

      <div className="col-lg-4 col-xl-3 d-none d-lg-flex align-items-center gradient-half-primary-v1 height-lg-100vh px-0">
      </div>
      <BuyingWizard {...props}></BuyingWizard>
    </div>
  );
}

export default WizardFrame;