import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import SearchAddress from './SearchAddress';

class LocationForm extends Component {

  handleClick(e) {
    e.preventDefault();

    this.props.history.push('/wizard')
  }
  onPlaceChanged(newPlace) {
    console.log(newPlace);
  }
  render() {

    return (

      <form className="js-validate" onSubmit={this.handleClick.bind(this)}>
        <div className="form-row mb-2">
          <div className="col-12 col-md-8 mb-3">
            <div className="js-form-message">
              <label className="sr-only" htmlFor="signupSrName">Address</label>
              <SearchAddress placeChanged={this.onPlaceChanged.bind(this)}></SearchAddress>
            </div>
          </div>


          <div className="col-md-4 mb-3">
            <button type="submit" className="btn btn-block btn-primary transition-3d-hover" >Get Started</button>
          </div>
        </div>


        <div className="js-form-message">
          <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
            <input type="checkbox" className="custom-control-input" id="termsCheckbox" name="termsCheckbox" required
              data-msg="Please accept our Terms and Conditions." />
            <label className="custom-control-label text-white" htmlFor="termsCheckbox">
              <small>
                I agree to the
              <a className="link-white" href="../pages/terms.html"> Terms and Conditions</a>
              </small>
            </label>
          </div>
        </div>

      </form>

    )
  }
}
export default withRouter(LocationForm);