import React, { Component } from 'react';

const Checkbox = props => (
    <input type="checkbox" {...props} />
)

class HouseTypeSelector extends Component {
    constructor(props) {
        super(props);
        if (props.selections)
            this.state = props.selections
        else
            this.state = {
                House: true,
                Condo: false,
                Flat: false,
                Multi: false,
                Land: false
            };
    }
    componentDidMount(){
        if (this.props.onSelectionChange)
            this.props.onSelectionChange(this.state);
    }

    handleCheckboxChange(event) {
        let newState = {};
        newState[event.target.value] = event.target.checked;
        this.setState(newState);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state)
            return;

        console.log("componentDidUpdate")
        if (this.props.onSelectionChange)
            this.props.onSelectionChange(this.state);
    }
    render() {
        return (
            <div className="mb-7">
                    <fieldset id="typeOfListingRadio" >

                        <div className="row mx-gutters-2">

                            <div className="col-6 col-md-3 ">
                                <div className="custom-control custom-radio custom-control-inline checkbox-outline text-center">
                                    <Checkbox id="typeOfListingRadio1" value="House" name="typeOfListingRadio" className="custom-control-input checkbox-outline__input"
                                        checked={this.state.House} onChange={this.handleCheckboxChange.bind(this)} />
                                    <label className="checkbox-outline__label rounded py-3 px-1 mb-0" htmlFor="typeOfListingRadio1">
                                        <img className="w-50 mb-3" src={require("../../assets/svg/flat-icons/small-house.svg")} alt="SVG" />
                                        <span className="d-block">House</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 mb-3 mb-md-0">
                                <div className="custom-control custom-radio custom-control-inline checkbox-outline text-center">
                                    <Checkbox id="typeOfListingRadio2" value="Flat" name="typeOfListingRadio" className="custom-control-input checkbox-outline__input"
                                        checked={this.state.Flat} onChange={this.handleCheckboxChange.bind(this)} />
                                    <label className="checkbox-outline__label rounded py-3 px-1 mb-0" htmlFor="typeOfListingRadio2">
                                        <img className="w-50 mb-3" src={require("../../assets/svg/flat-icons/multi-family-house.svg")} alt="SVG" />
                                        <span className="d-block">Townhome</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="custom-control custom-radio custom-control-inline checkbox-outline text-center">
                                    <Checkbox id="typeOfListingRadio4" value="Condo" name="typeOfListingRadio" className="custom-control-input checkbox-outline__input"
                                        checked={this.state.Condo} onChange={this.handleCheckboxChange.bind(this)} />
                                    <label className="checkbox-outline__label rounded py-3 px-1 mb-0" htmlFor="typeOfListingRadio4">
                                        <img className="w-50 mb-3" src={require("../../assets/svg/flat-icons/flat-house.svg")} alt="SVG" />
                                        <span className="d-block">Condo</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="custom-control custom-radio custom-control-inline checkbox-outline text-center">
                                    <Checkbox id="typeOfListingRadio5" value="Land" name="typeOfListingRadio" className="custom-control-input checkbox-outline__input"
                                        checked={this.state.Land} onChange={this.handleCheckboxChange.bind(this)} />
                                    <label className="checkbox-outline__label rounded py-3 px-1 mb-0" htmlFor="typeOfListingRadio5">
                                        <img className="w-50 mb-3" src={require("../../assets/svg/flat-icons/farm-land.svg")} alt="SVG" />
                                        <span className="d-block">Land</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
            </div>);
    }
}



export default HouseTypeSelector;