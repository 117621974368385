import React, { Component } from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Interests from './InterestsThin';
import { withRouter } from 'react-router-dom'
import SearchBar from './SearchBar';

const numberMask = createNumberMask({
    prefix: '',
    suffix: ''
});
class MapHeader extends Component {

    constructor(props) {

        super(props);
        this.state = {};

        if (props.interests)
            this.state.interests = props.interests;
        else
            this.state.interests = null;

        this.state.hasChanges = false;
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("mapheader control updated");
        if (prevState.interests !== this.state.interests)
            this.props.onChange(this.state.interests);
    }
    onInterestsChange(newInterests) {
        this.setState({ "interests": newInterests });
        console.log("mapheader control interested changed");
    }
    goBack() {
        this.props.history.goBack();
    }
    render() {
        return (
            <div class="bg-primary">
                <div id="accordion">
                    <div class="card bg-primary">
                        <div class="card-header bg-primary" id="headingOne">
                            <h5 class="mb-0">
                                <div class="row justify-content-between flex-nowrap pb-2">
                                    <div class="col-2">
                                        <button type="button" class=" btn btn-icon btn-light transition-3d-hover" onClick={() => this.goBack()}>
                                            <i class="fas fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <div class="col-5 order-lg-1 text-lg-left d-none d-md-block flex-nowrap">
                                        <div class="d-flex">
                                            <ol class="breadcrumb breadcrumb-white breadcrumb-no-gutter mb-0">
                                                <li class="breadcrumb-item"><a class="breadcrumb-link" href="../home/index.html">Home</a></li>
                                                <li class="breadcrumb-item"><a class="breadcrumb-link" href="./home/index.html">Houston</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Map Results</li>
                                            </ol>


                                        </div>
                                    </div>
                                    <div class="col-auto order-lg-2  text-lg-right">
                                        <SearchBar placeChanged={this.props.placeSearched}></SearchBar>
                                    </div>
                                </div>
                                <button class="btn btn-link btn-white" data-toggle="collapse" data-target="#collapseFilters" aria-expanded="true" aria-controls="collapseOne">
                                    Toggle Editing Interests
                                </button>
                                <div id="collapseFilters" class="collapse hide" aria-labelledby="headingFilters" data-parent="#accordion">
                                    <Interests interests={this.state.interests} onChange={this.onInterestsChange.bind(this)}></Interests>
                                </div>

                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MapHeader);