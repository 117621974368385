import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Tooltip = (props) => {
    const [road, setRoad] = useState({...props.road});
    useEffect(() => {
        // const fetchData = async () => {
        //     const res = await axios(
        //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.lngLat.lat},${props.lngLat.lng}&sensor=true&key=AIzaSyDPjDykVJ7f2CAEAU9yv4jjKE0Ie5x-uwQ`,
        //         {
        //             crossDomain: true
        //         });
        //     let result = res.data.results[0];
        //     let address = result.formatted_address;
        //     let latlong = result.geometry.location;
        // };
        setRoad(props.road);
    }, [props.road]);


    function quantileToColor(val, property) {
        if(!val || !property in val)
            return null;
        let value = val[property];
        if (value > .8)
            return "green";
        if (value > .6)
            return "blue";
        if (value > .4)
            return "yellow";
        if (value > .2)
            return "orange";
        if (value > .0)
            return "red";
    }
    const close = () => {
        props.popup._onClickClose.call();
    }
    function onDetailsClicked (e){
        if(props.onGetDetails){
            close();
            props.onGetDetails({"point" :props.point, "lngLat" :props.lngLat, "roadProps" : props.road});
        }
    }
    return (
        <div class="card card-frame card-frame-highlighted">
            <header class="card-header bg-light py-3 px-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-0 mr-2">{props.address || "No road found"}</div>

                    <button type="button" class="close" aria-label="Close" onClick={() => close()}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </header>
            <div class="card-body">
                {/* <p>{JSON.stringify(props.eventArgs.lngLat.lat)}</p> */}
                {/* <p>{JSON.stringify(road)}</p> */}
                <div class="row pb-3">
                    <button type="button" class="col-5 btn btn-soft-secondary m-2 btn-sm" onClick={() => close()}>Close</button>
                    <button type="button" class="col-5 btn btn-soft-primary m-2 btn-sm" onClick={onDetailsClicked}>Details</button>
                </div>
                <div class="card-title">
                    <h5>Summary</h5>
                </div>
                <div class="d-flex flex-row flex-wrap justify-content-around">
                    <div class="col-5 m-1 card">
                        <div class="card-title">Crime</div>
                        <i class="fas fa-user-shield h3" style={{ "color": quantileToColor(road, "crime_avg_quant") }}></i>

                    </div>
                    <div class="col-5 m-1 card">
                        <div class="card-title">Traffic</div>
                        <i class="fas fa-traffic-light h3" style={{ "color": quantileToColor(road, "highway_val_agg_quant") }}></i>


                    </div>

                    <div class="col-5 m-1 card">
                        <div class="card-title">Parks</div>
                        <i class="fas fa-tree h3" style={{ "color": quantileToColor(road, "parks_avg_quant") }}></i>

                    </div>
                    <div class="col-5 m-1 card">
                        <div class="card-title">Things to do</div>
                        <i class="fas fa-store-alt h3" style={{ "color": quantileToColor(road, "poi_avg_quant") }}></i>

                    </div>

                    <div class="col-5 m-1 card">
                        <div class="card-title">Bars</div>
                        <i class="fas fa-glass-martini-alt h3" style={{ "color": quantileToColor(road, "bars_avg_quant") }}></i>

                    </div>

                    <div class="col-5 m-1 card">
                        <div class="card-title">Food</div>
                        <i class="fas fa-utensils h3" style={{ "color": quantileToColor(road, "restaurants_avg_quant") }}></i>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Tooltip;