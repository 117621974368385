import React, { Component, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl'
import '../../assets/css/map.css';
import mapbox_style from './style.json';
import { ScatterplotLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import { withRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import Tooltip from './Tooltip';

mapboxgl.accessToken = 'pk.eyJ1IjoiZGVlcHYiLCJhIjoiY2p3YXV2Z2h4MDM5eDQ4bnJncG9oNXRoaSJ9.0hWMYIlD-70yVZzaLUVfyg';
String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    let updatedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    return target.replace(new RegExp(updatedSearch, 'g'), replacement);
};

const map_container_style = {
    position: "relative",
}

const map_style = {
}
function getHoustonLayers(mixed_style) {
    let customLayers = [];
    for (let layer of mixed_style.layers) {
        if (layer.id.startsWith("houston")) {
            customLayers.push(layer);
        }
    }
    return customLayers;
}
let map_style_str = JSON.stringify(mapbox_style);
class Mapbox extends Component {
    map;

    constructor(props) {

        super(props);
        this.state = {};
        if (props.weights)
            this.state.weights = props.weights
    }

    getUpdatedStyle(weights) {
        let replaced_style = map_style_str.replaceAll('["highway_weight"]', weights["highwayWeight"])
            .replaceAll('["crime_weight"]', weights["crimeWeight"])
            .replaceAll('["poi_weight"]', weights["poiWeight"])
            .replaceAll('["bars_weight"]', weights["barsWeight"])
            .replaceAll('["restaurants_weight"]', weights["restaurantsWeight"])
            .replaceAll('["parks_weight"]', weights["parksWeight"]);
        return JSON.parse(replaced_style);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        console.log("updating props");
        if (nextProps.weights !== this.state.weights) {
            this.setState({ weights: nextProps.weights });
            console.log("updating style");
            this.map.setStyle(this.getUpdatedStyle(nextProps.weights));
        }
        if (nextProps.selectedLngLat !== this.state.selectedLngLat) {

            this.setState({ selectedLngLat: nextProps.selectedLngLat });
            if(nextProps.selectedLngLat && nextProps.selectedLngLat.lng && nextProps.selectedLngLat.lat)
                this.loadPopup(nextProps.selectedLngLat);
        }
    }
    componentDidMount() {
        // console.log(mapbox_style);
        // window.mapbox_style = mapbox_style;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            center: [-95.36782, 29.74635],
            zoom: 12,
            style: this.getUpdatedStyle(this.state.weights),
            hash: true,
            transformRequest: (url, resourceType) => {
                if (resourceType === 'Source' && url.startsWith('http://myHost')) {
                    return {
                        url: url.replace('http', 'https'),
                        headers: { 'my-custom-header': true },
                        credentials: 'include'  // Include cookies for cross-origin requests
                    }
                }
            }
        });
        // Create a popup, but don't add it to the map yet.
        this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: true,
        });
        this.popup.setHTML(`<div id="my-react-card-popup"></div>`);
        window.map = this.map;
        let map = this.map;

        this.map.on("click", (e) => {
            this.loadPopup(e.lngLat, e.point);
        });
        if(this.props.selectedLngLat && Object.keys(this.props.selectedLngLat).length > 0)
            this.loadPopup(this.props.selectedLngLat)
    }

    loadPopup(lngLat, point = null) {
        if(!this.map || !lngLat)
            return;
        let newPoint = point;
        if (newPoint == null) {
            // this.map.panTo([lngLat.lng, lngLat.lat]);
            this.map.flyTo({ "center": [lngLat.lng, lngLat.lat] });
            newPoint = this.map.project([lngLat.lng, lngLat.lat]);
        }
        let features = this.getFeatures(newPoint);
        let road = null
        let address = null;
        if (features && features.length >= 1) {
            road = features[0].properties;
            address = features[0].properties.name || "Unknown";
        }
        else{
            // TODO: error handling
            return;

        }
        // console.log(JSON.stringify(features, null, 2));
        this.popup.setLngLat(lngLat)
            .addTo(this.map);
        ReactDOM.render(<Tooltip popup={this.popup} point={newPoint} lngLat={lngLat} road={road} address={address} onGetDetails={this.getDetails.bind(this)} />, document.getElementById("my-react-card-popup"));



    }
    ///Allow some fuzziness in the user's clicking
    getFeatures(point, adjustment = 0) {
        if (adjustment == 10)
            return null;
        let features = null;
        
        if (adjustment == 0)
            features = this.map.queryRenderedFeatures([point.x, point.y], {
                layers: ["houstonHeatmap", "houston-roads"]
            });
        else {

            let bbox = [[point.x - adjustment, point.y - adjustment], [point.x + adjustment, point.y + adjustment]];
            features = this.map.queryRenderedFeatures(bbox, {
                layers: ["houstonHeatmap", "houston-roads"]
            });
        }
        if (features == null || features.length < 1) {
            features = this.getFeatures(point, adjustment + 1);
        }
        return features;
    }
    getDetails(data) {
        if (this.props.selectedRoadChanged)
            this.props.selectedRoadChanged(data);
        this.props.history.push(`/results/road/${data.roadProps.osmid}`);
    }
    render() {
        return (
            <div ref={el => this.mapContainer = el} className="mapbox-container" style={map_style} />
        );
    }
}
export default withRouter(Mapbox);