import React from 'react';
function Header() {
  return <header id="header" className="u-header u-header--bg-transparent u-header--abs-top  u-header--white-bg-md u-header--white-bg-sm u-header--white-bg-xs">
    <div className="u-header__section">
      <div id="logoAndNav" className="container-fluid">
        <nav className="navbar navbar-expand u-header__navbar">
          <a className="d-none d-lg-flex navbar-brand u-header__navbar-brand u-header__navbar-brand-center u-header__navbar-brand-text-white" href="/" aria-label="Front">
            <span className="u-header__navbar-brand-text">
              <p style={{ textShadow: "-0.5px -0.5px 0 #737373, 0.5px -0.5px 0 #737373, -0.5px 0.5px 0 #737373, 0.5px 0.5px 0 #737373", color: "white", fontFamily: "'Montserrat Alternates', sans-serif", fontSize: "52px" }}>Stroll</p></span>

          </a>

          <a className="d-flex d-lg-none navbar-brand u-header__navbar-brand u-header__navbar-brand-center u-header__navbar-brand-collapsed" href="/" aria-label="Front">
            <span className="d-inline-block u-header__navbar-brand-text">
              <p style={{ textShadow: "-0.5px -0.5px 0 #737373, 0.5px -0.5px 0 #737373, -0.5px 0.5px 0 #737373, 0.5px 0.5px 0 #737373", color: "#5BB3E7", fontFamily: "'Montserrat Alternates', sans-serif", fontSize: "52px" }}>Stroll</p></span>
          </a>
        </nav>
      </div>
    </div>
  </header>;
}
export default Header;