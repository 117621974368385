import React, { Component, useState, useEffect } from 'react';
import '../../assets/css/map.css';
import Mapbox3 from './Mapbox3';
import Mapbox from './Mapbox';
import MapHeader from './MapHeader';
import RoadDetails from './Road/RoadDetails';
import { Route, BrowserRouter as Router, Switch, Redirect, withRouter } from 'react-router-dom'

const Map = (props) => {
    const [weights, setWeights] = useState(getInitWeights);

    const [interests, setInterests] = useState(props.interests || {
        parks: { value: 1 },
        restaurants: { value: 0 },
        nightlife: { value: 0 },
        walking: { value: 1 },
        kid: { value: 2 },
        quiet: { value: 2 },
    });
    const [selectedLngLat, setselectedLngLat] = useState({});

    const [roadData, setRoadData] = useState({});

    function setLatLong(lat, long){
        setselectedLngLat({"lat":lat, "lng":long});
    }


    function getNormalizeVals(newWeights) {
        let norm_weights = { ...newWeights };
        let factor = getNormFactor(newWeights);
        norm_weights["highwayWeight"] *= factor;
        norm_weights["crimeWeight"] *= factor;
        norm_weights["poiWeight"] *= factor;
        norm_weights["barsWeight"] *= factor;
        norm_weights["restaurantsWeight"] *= factor;
        norm_weights["parksWeight"] *= factor;
        return norm_weights;
    }
   function getNormFactor(newWeights) {
        let total = newWeights.highwayWeight + newWeights.crimeWeight + newWeights.poiWeight +
            newWeights.barsWeight + newWeights.restaurantsWeight + newWeights.parksWeight;
        if (total !== 0)
            return 1 / total;
        return 0;
    }
    function getWeightsByInterests(newInterests) {
        let new_weights = {};
        new_weights["highwayWeight"] = Math.pow(newInterests["walking"].value + newInterests["quiet"].value, 2);
        new_weights["crimeWeight"] = Math.pow(newInterests["kid"].value, 2);
        new_weights["poiWeight"] = Math.pow(newInterests["nightlife"].value + newInterests["restaurants"].value, 2);
        new_weights["barsWeight"] = Math.pow(newInterests["nightlife"].value, 2);
        new_weights["parksWeight"] = Math.pow(newInterests["parks"].value, 2);
        new_weights["restaurantsWeight"] = Math.pow(newInterests["restaurants"].value, 2);
        let norm_weights = getNormalizeVals(new_weights); 
        return norm_weights;
    }

    useEffect(() => {
        if(roadData && roadData.lngLat)
            setLatLong(roadData.lngLat.lat, roadData.lngLat.lng)
        if(props.location.pathname.startsWith('/results/road/') && !(roadData  && roadData.roadProps ) )
            props.history.push('/results');
        
     }, [roadData, props]);

     useEffect(() => {
        setWeights(getWeightsByInterests(interests));
     }, [interests]);
     
    function getInitWeights() {
        if (props.weights)
            return props.weights
        else
            return {
                'highwayWeight': 0.5,
                'crimeWeight': .25,
                'poiWeight': 0.0,
                'parksWeight': .25,
                'barsWeight': 0,
                'restaurantsWeight': 0
            };

    }
    const selectedRoadChanged = (data) => {
        if (data !== roadData) {
            setRoadData(data);
        }
    }
    const placeSearched = (placeObj) => {
        setLatLong(placeObj.raw.geometry.location.lat(), placeObj.raw.geometry.location.lng());
    }
    
    return (
        <div className="map-tool-container">
            <div>
                <MapHeader weights={weights} onChange={(newInterests) => setInterests(newInterests)} interests={interests} placeSearched={placeSearched}></MapHeader>
            </div>
            <div style={{ height: "90vh" }}>

                <Router>
                    <Switch>
                        <Route path={`/results/road/:roadId`} render={(props) => {return (roadData && roadData.roadProps && <RoadDetails {...props} roadData={roadData} selectedLngLat={selectedLngLat}> </RoadDetails>)}}  />
                        <Route render={(props) => <Mapbox {...props} weights={weights} selectedRoadChanged={selectedRoadChanged} selectedLngLat={selectedLngLat}></Mapbox>} />
                    </Switch>
                </Router>

            </div>
        </div>
    );
}
export default withRouter(Map);
