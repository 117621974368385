import React, { Component } from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// First, you need to create the `numberMask` with your desired configurations
const numberMask = createNumberMask({
    prefix: '$',
    suffix: ''
});
class PriceRange extends Component {

    constructor(props) {

        super(props);
        if (props.priceRange)
            this.state = props.priceRange
        else
            this.state = {
                'minPrice': '',
                'maxPrice': '',
                'monthlyBudget': '',
                'annualIncome': ''
            };
    }
    componentDidMount() {
        if (this.props.onChange)
            this.props.onChange(this.state);
    }
    onChange(e) {
        let parsed = NaN;
        if (e.target.value)
            parsed = Number(e.target.value.replace(/[^0-9\.]+/g, ""));
        this.setState({ [e.target.name]: parsed })

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state)
            return;

        if (this.props.onChange)
            this.props.onChange(this.state);
    }
    render() {
        return (
            <div className="mb-4">
                <form className="js-validate mt-5">

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <div className="js-form-message">
                                <label className="" htmlFor="minPrice">Minimum Price</label>
                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="listingPriceLabel">
                                            <span className="fas fa-dollar-sign"></span>
                                        </span>
                                    </div>
                                    <MaskedInput mask={numberMask}  onChange={this.onChange.bind(this)} value={this.state.minPrice}
                                        className="form-control" name="minPrice" id="minPrice" placeholder="Min Price" aria-label="Min Price" required="" data-msg="Price must contain only numbers" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="js-form-message">
                                <label className="" htmlFor="maxPrice">Maximum Price</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="listingPriceLabel">
                                            <span className="fas fa-dollar-sign"></span>
                                        </span>
                                    </div>
                                    <MaskedInput mask={numberMask} onChange={this.onChange.bind(this)} value={this.state.maxPrice}
                                        className="form-control" name="maxPrice" id="maxPrice" placeholder="Max Price" aria-label="Max Price" required="" data-msg="Price must contain only numbers" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <p>
                        <button className="btn btn-text btn-text-secondary d-none" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            Unsure?
                            </button>
                    </p>
                    <div className="collapse" id="collapseExample">
                        <div className="card card-body" >

                            <div className="js-form-message pb-2 mb-3">
                                <label className="form-label" htmlFor="signupSrEmail">Monthly Budget</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="listingPriceLabel">
                                            <span className="fas fa-dollar-sign"></span>
                                        </span>
                                    </div>
                                    <MaskedInput mask={numberMask} onChange={this.onChange.bind(this)} value={this.state.monthlyBudget}
                                        className="form-control" name="monthlyBudget" id="monthlyBudget" placeholder="Monthly Budget" aria-label="Monthly Budget" required="" data-msg="Price must contain only numbers" />
                                </div>
                            </div>

                            <div className="pb-1 mb-3">
                                <p className="text-secondary mb-0">OR</p>

                            </div>

                            <div className="js-form-message">
                                <label className="form-label" htmlFor="signupSrEmail">Annual Household Income</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="listingPriceLabel">
                                            <span className="fas fa-dollar-sign"></span>
                                        </span>
                                    </div>
                                    <MaskedInput mask={numberMask} onChange={this.onChange.bind(this)} value={this.state.annualIncome}
                                        className="form-control" name="annualIncome" id="annualIncome" placeholder="Annual Income" aria-label="Annual Income" required="" data-msg="Price must contain only numbers" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default PriceRange;