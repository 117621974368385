import React, { useState } from 'react';
import ReactStreetview from 'react-streetview';
import { withRouter } from 'react-router-dom'


const RoadDetails = (props) => {


    function quantileToColor(value, property) {
        if (value > .8)
            return "green";
        if (value > .6)
            return "blue";
        if (value > .4)
            return "yellow";
        if (value > .2)
            return "orange";
        if (value > .0)
            return "red";
    }
    // see https://developers.google.com/maps/documentation/javascript
    const googleMapsApiKey = 'AIzaSyDPjDykVJ7f2CAEAU9yv4jjKE0Ie5x-uwQ';
    // see https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
    const [streetViewPanoramaOptions, setOptions] = useState({
        "position": { "lat": props.selectedLngLat.lat, "lng": props.selectedLngLat.lng },
        "pov": { "heading": 100, "pitch": 0 },
        "zoom": 1
    });

    return (
        <div class="vh-100">
            <div style={{ backgroundColor: '#eeeeee' }} class="w-100 h-50">
                <ReactStreetview apiKey={googleMapsApiKey} streetViewPanoramaOptions={streetViewPanoramaOptions} />
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-around">
                <div class="col-5 m-1 card">
                    <div class="card-title">Crime</div>
                    <i class="fas fa-user-shield h3" style={{ "color": quantileToColor(props.roadData.roadProps.crime_avg_quant, "crime_avg_quant") }}></i>

                </div>
                <div class="col-5 m-1 card">
                    <div class="card-title">Traffic</div>
                    <i class="fas fa-traffic-light h3" style={{ "color": quantileToColor(props.roadData.roadProps.highway_val_agg_quant, "highway_val_agg_quant") }}></i>


                </div>

                <div class="col-5 m-1 card">
                    <div class="card-title">Parks</div>
                    <i class="fas fa-tree h3" style={{ "color": quantileToColor(props.roadData.roadProps.parks_avg_quant, "parks_avg_quant") }}></i>

                </div>
                <div class="col-5 m-1 card">
                    <div class="card-title">Things to do</div>
                    <i class="fas fa-store-alt h3" style={{ "color": quantileToColor(props.roadData.roadProps.poi_avg_quant, "poi_avg_quant") }}></i>

                </div>

                <div class="col-5 m-1 card">
                    <div class="card-title">Bars</div>
                    <i class="fas fa-glass-martini-alt h3" style={{ "color": quantileToColor(props.roadData.roadProps.bars_avg_quant, "bars_avg_quant") }}></i>

                </div>

                <div class="col-5 m-1 card">
                    <div class="card-title">Food</div>
                    <i class="fas fa-utensils h3" style={{ "color": quantileToColor(props.roadData.roadProps.restaurants_avg_quant, "restaurants_avg_quant") }}></i>

                </div>

            </div>

        </div >
    );
}
export default withRouter(RoadDetails);