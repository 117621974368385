import React from 'react';
import '../assets/css/landing.css';
import Header from "../components/Wizard/Header";
import Map from "./Map/Map"

const Results = (props) =>
    (
        <div className="">
            {/* <Header></Header> */}
            {/* <div className="container space-2">
                <div className="w-lg-75 mx-lg-auto">

                    <div id="SVGhouseAgency" className="order-lg-1 svg-preloader-old mb-2">
                        <div className="pr-lg-7">
                            <figure className="ie-house-agency">
                                <img className="js-svg-injector" src={require("../assets/svg/flat-icons/subscribe-1.svg")} 
                                    data-parent="#SVGhouseAgency" />
                            </figure>
                        </div>
                    </div>
                    <div className="text-center mb-9">
                        <h1 className="h2 font-weight-medium">We're combing through the listings to find your dream house</h1>
                        <p className="mb-0">Check back with us, we're working on listing live results.</p>
                    </div>


                </div>
            </div>
             */}
            <Map {...props}></Map>
        </div>
    )
export default Results;