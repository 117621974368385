import React, { Component } from 'react';
import "../../assets/vendor/ion-rangeslider/css/ion.rangeSlider.css";
class Interests extends Component {

    constructor(props) {

        super(props);
        if (props.interests)
            this.state = props.interests
        else
            this.state = {
                parks: { text: "Nice to have", value: 1 },
                restaurants: { text: "Nice to have", value: 1 },
                nightlife: { text: "Nice to have", value: 1 },
                walking: { text: "Nice to have", value: 1 },
                kid: { text: "Nice to have", value: 1 },
                quiet: { text: "Nice to have", value: 1 },
            };
    }
    componentDidMount() {
        window.$.HSCore.components.HSRangeSlider.init('.js-range-slider')
        let interestsObj = this;
        for (let item of window.$(".js-range-slider")) {
            let $item = window.$(item);

            //check to see if we need to update the control with passed in props values
            let sliderFor = $item.attr("data-sliderfor");
            if (true || sliderFor in this.state) {
                $item.data("ionRangeSlider").update({ from: this.state[sliderFor].value });
            }


            //subscribe to each slider's jquery onChange event to update the state
            $item.on("change", function () {
                let $inp = window.$(this);
                let text = $inp.prop("value"); // reading input value
                let val = $inp.data("from"); // reading input data-from attribute
                let sliderFor = $inp.attr("data-sliderfor"); // reading input data-from attribute
                let stateUpdate = {};
                stateUpdate[sliderFor] = { text: text, value: val };
                interestsObj.setState(stateUpdate);
            })
        }

        if (this.props.onChange)
            this.props.onChange(this.state);

    }

    componentWillUnmount() {
        for (let item of window.$(".js-range-slider")) {
            window.$(item).off("change");
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state)
            return;

        if (this.props.onChange)
            this.props.onChange(this.state);
    }
    render() {
        return (
            <div className=" mb-4">
                <div className="list-group">
                    <div className="list-group-item flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Parks</h5>
                        </div>
                        <input className="js-range-slider " type="text"
                            data-extra-classes="u-range-slider u-range-slider-indicator"
                            data-type="single"
                            data-hide-from-to="false"
                            data-force-edges="true"
                            data-from="1"
                            data-sliderfor="parks"
                            data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
                    </div>
                    <div className="list-group-item flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Restaurants</h5>
                        </div>
                        <input className="js-range-slider " type="text"
                            data-extra-classes="u-range-slider u-range-slider-indicator"
                            data-type="single"
                            data-hide-from-to="false"
                            data-force-edges="true"
                            data-from="1"
                            data-sliderfor="restaurants"
                            data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
                    </div>
                    <div className="list-group-item flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Nightlife</h5>
                        </div>
                        <input className="js-range-slider " type="text"
                            data-extra-classes="u-range-slider u-range-slider-indicator"
                            data-type="single"
                            data-hide-from-to="false"
                            data-force-edges="true"
                            data-from="1"
                            data-sliderfor="nightlife"
                            data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
                    </div>
                    <div className="list-group-item flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Walking</h5>
                        </div>
                        <input className="js-range-slider " type="text"
                            data-extra-classes="u-range-slider u-range-slider-indicator"
                            data-type="single"
                            data-hide-from-to="false"
                            data-force-edges="true"
                            data-from="1"
                            data-sliderfor="walking"
                            data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
                    </div>
                    <div className="list-group-item flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Kid friendly</h5>
                        </div>
                        <input className="js-range-slider " type="text"
                            data-extra-classes="u-range-slider u-range-slider-indicator"
                            data-type="single"
                            data-hide-from-to="false"
                            data-force-edges="true"
                            data-from="1"
                            data-sliderfor="kid"
                            data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
                    </div>
                    <div className="list-group-item flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Quiet</h5>
                        </div>
                        <input className="js-range-slider " type="text"
                            data-extra-classes="u-range-slider u-range-slider-indicator"
                            data-type="single"
                            data-hide-from-to="false"
                            data-force-edges="true"
                            data-from="1"
                            data-sliderfor="quiet"
                            data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Interests;