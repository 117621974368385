import React, { Component } from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import HouseTypeSelector from './HouseTypeSelector';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import '../../assets/css/wizard.css';
import PriceRange from './PriceRange.js';
import Interests from './Interests.js';
import Contact from './Contact';
import { Line } from 'rc-progress';
import Header from './Header';

export class SinglePageWizard extends Component {
    state = {
        propertySelections: null,
        interests: null,
        priceRange: null,
        contactInfo: null
    }
    houseSelectionChanged(propertySelections) {
        this.setState({ propertySelections: propertySelections })
    }
    priceRangeChanged(newPriceRange) {
        this.setState({ priceRange: newPriceRange })
    }
    interestsChanged(newInterests) {
        this.setState({ interests: newInterests })
    }
    contactInfo(newContactInfo) {
        this.setState({ contactInfo: newContactInfo })
    }

    OnComplete() {
        console.log(this.state);
        console.log("redirecting");
        this.props.history.push(`/results`)
    }

    render() {
        return (

            <div className="d-flex align-items-center position-relative height-lg-100vh">
                <Header></Header>

                <div className="col-lg-4 col-xl-3 d-none d-lg-flex align-items-center gradient-half-primary-v1 height-lg-100vh px-0">
                </div>
                <div id="wizard" className="flex-fill" style={{ position: "relative", height: "100%", width: "100%", overflow: "auto" }}>
                    <div className="height-100vh">
                        <BrowserRouter basename="/singleWizard">
                            <Route
                                render={({ history, match: { url } }) => (
                                    <Wizard
                                        history={history}
                                        basename={url}
                                        render={({ step, steps }) => (
                                            <div>
                                                <Line percent={(steps.indexOf(step) + 1) / steps.length * 100}
                                                    className="pad-b  mt-11 mt-lg-5 mt-xl-5 mx-11  px-lg-3 px-xl-3 px-md-3" />
                                                <TransitionGroup>
                                                    <CSSTransition
                                                        key={step.id}
                                                        classNames="buyWizard"
                                                        timeout={{ enter: 300, exit: 300 }}>
                                                        <div className="buyWizard-steps fluid  w-75 mx-auto">
                                                            <div className=" my-11">

                                                                <Steps key={step.id} step={step}>
                                                                    <Step
                                                                        id="propertyType"
                                                                        render={({ next, previous }) => (
                                                                            <div>

                                                                                <div className="mb-7 text-left">
                                                                                    <h1 className="h3 text-primary font-weight-normal mb-0">Tell me about what you're <span className="font-weight-semi-bold">dreaming</span> of</h1>
                                                                                    <p>Leave anything blank that you're undecided on </p>
                                                                                </div>
                                                                                <div className="pb-4">
                                                                                    <div className="border-bottom pb-3 mb-5 d-flex justify-content-start">
                                                                                        <h2 className="h6 mb-0">What type of home?</h2>
                                                                                    </div>
                                                                                    <HouseTypeSelector onSelectionChange={this.houseSelectionChanged.bind(this)} selections={this.state.propertySelections}></HouseTypeSelector>

                                                                                </div>

                                                                                <div className="pb-4">
                                                                                    <div className="border-bottom pb-3 mb-5 d-flex justify-content-start">
                                                                                        <h2 className="h6 text mb-0">Do you have a budget in mind?</h2>
                                                                                    </div>
                                                                                    <PriceRange onChange={this.priceRangeChanged.bind(this)} priceRange={this.state.priceRange}></PriceRange>
                                                                                </div>
                                                                                <div className="pb-4">
                                                                                    <div className="border-bottom pb-3 mb-5 d-flex justify-content-start">
                                                                                        <h2 className="h6 mb-0">What features are important to you?</h2>
                                                                                    </div>
                                                                                    <Interests onChange={this.interestsChanged.bind(this)} interests={this.state.interests}></Interests>
                                                                                    <div className="row">
                                                                                        <div className=" col-5 col-sm-6 text-right">
                                                                                        </div>
                                                                                        <div className=" col-5 col-sm-6 text-right">
                                                                                            <button className="btn  btn-wide btn-primary transition-3d-hover" onClick={next}>Next</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                    <Step
                                                                        id="contact"
                                                                        render={({ next, previous }) => (
                                                                            <div>
                                                                                <div className="mb-7 text-left">
                                                                                    <h1 className="h3 text-primary font-weight-normal mb-0">Great! We're crunching your preferences</h1>
                                                                                    <p>How can we contact you when we find your dream houses?</p>
                                                                                </div>
                                                                                <Contact onChange={this.contactInfo.bind(this)} contactInfo={this.state.contactInfo}></Contact>
                                                                                <div className="row">
                                                                                    <div className=" col-5 col-sm-6 text-right">
                                                                                        <button className="btn  btn-wide btn-link transition-3d-hover" onClick={previous}>Previous</button>
                                                                                    </div>
                                                                                    <div className=" col-5 col-sm-6 text-left">
                                                                                        <button className="btn  btn-wide btn-primary transition-3d-hover" onClick={this.OnComplete.bind(this)}>Finish</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </Steps>
                                                            </div>
                                                        </div>
                                                    </CSSTransition>
                                                </TransitionGroup>
                                            </div>

                                        )} />
                                )} />
                        </BrowserRouter>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(SinglePageWizard);