import React, { useState, useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';
import './assets/vendor/font-awesome/css/fontawesome-all.min.css';
import './assets/vendor/animate.css/animate.min.css';
import './assets/vendor/hs-megamenu/src/hs.megamenu.css';
import './assets/vendor/fancybox/jquery.fancybox.css';
import './assets/vendor/slick-carousel/slick/slick.css';
import './assets/css/theme.css';
import Landing from './components/Landing';
import WizardFrame from "./components/Wizard/WizardFrame";
import SinglePageWizard from "./components/Wizard/SinglePageWizard";
import Results from './components/Results';
import Page404 from './components/Error404';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

const Routing = (props) => {
  const [wizardResults, setwizardResults] = useState({});
  useEffect(() => { console.log("abcdefg - " + wizardResults)}, [wizardResults]);


  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/wizard" render={() => <WizardFrame onComplete={(results) => setwizardResults(results)} />} />
          <Route path="/singleWizard" component={SinglePageWizard} />
          <Route path="/results" render={() => <Results interests={wizardResults.interests} />} />
          <Route path="/Error" component={Page404} />
          <Route component={Page404} />
          {/* <Route path="/contact" component={Contact} /> */}
        </Switch>
      </div>
    </Router>
  )
}

function App() {
  return (
    <div className="App">

      <Routing></Routing>
    </div>
  );
}

export default App;
