import React, { Component, useState } from 'react';
import ReactMapGL from "react-map-gl";
import DeckGL, { ArcLayer } from "deck.gl";
import '../../assets/css/map.css';
import mapbox_style from './style.json';

const accessToken = 'pk.eyJ1IjoiZGVlcHYiLCJhIjoiY2p3YXV2Z2h4MDM5eDQ4bnJncG9oNXRoaSJ9.0hWMYIlD-70yVZzaLUVfyg';
String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    let updatedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    return target.replace(new RegExp(updatedSearch, 'g'), replacement);
};

let map_style_str = JSON.stringify(mapbox_style);
class Mapbox3 extends Component {
    constructor(props) {

        super(props);
        this.state = {
            viewport: {
                height: "100%",
                width: "100%",
                longitude: -95.36782,
                latitude: 29.74635,
                zoom: 12,
            }
        };
        if (props.weights) {
            this.state.weights = props.weights
            this.state.mapStyle = this.getUpdatedStyle();
        }
    }

    getUpdatedStyle() {
        let weights = this.state.weights;
        let replaced_style = map_style_str.replaceAll('["highway_weight"]', weights["highwayWeight"])
            .replaceAll('["crime_weight"]', weights["crimeWeight"])
            .replaceAll('["poi_weight"]', weights["poiWeight"])            
            .replaceAll('["bars_weight"]', weights["barsWeight"])
            .replaceAll('["restaurants_weight"]', weights["restaurantsWeight"])
            .replaceAll('["parks_weight"]', weights["parksWeight"]);
        return JSON.parse(replaced_style);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.weights !== this.state.weights) {
            this.setState({ weights: nextProps.weights }, ()=>{
                this.setState({ "mapStyle": this.getUpdatedStyle() });
            });
            
        }
    }
    
    onViewportChange = newViewport => {
        this.setState({ viewport: newViewport });
    };
    render() {
        return (
            <div className="mapbox-container">
                <ReactMapGL
                    {...this.state.viewport}
                    onViewportChange={this.onViewportChange.bind(this)}
                    mapboxApiAccessToken={accessToken}
                    mapStyle={this.state.mapStyle}
                    mapOptions={{ hash: true }}>
                </ReactMapGL>
            </div>
        );
    }
}

export default Mapbox3;