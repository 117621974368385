import React, { Component } from 'react';
import logo from '../assets/img/logos/modeled_alpha.png';
import logoFav from '../assets/img/logos/modeled_fav.png';
import LocationForm from './LocationForm';
import '../assets/css/landing.css';
import backgroundImage from '../assets/img/1920x1920/sunset-home-1920.jpg';

const background_image= {
  backgroundImage: 'url(' + backgroundImage + ')',
  height:"100vh"
}

class Landing extends Component { 
  componentDidMount(){
    window.$.HSCore.components.HSHeader.init(window.$('#header'));
  }
  render(){
    return(
  <div className="App">


  <header id="header" className="u-header u-header--white-bg-on-scroll u-header--floating u-header--bg-transparent-md"         data-header-fix-moment="500"
          data-header-fix-effect="slide">
        <div id="logoAndNav" className="container">
          <div className="">
            
            <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space  p-4">
              
              <a className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center" href="index.html" aria-label="Stroll.ai">
                {/* <img src={logo}/> */}
                <p style={{textShadow:"-0.5px -0.5px 0 #737373, 0.5px -0.5px 0 #737373, -0.5px 0.5px 0 #737373, 0.5px 0.5px 0 #737373", color:"#5BB3E7", fontFamily: "'Montserrat Alternates', sans-serif", fontSize:"52px"}}>Stroll</p>
                
              </a>
              
            </nav>
            
          </div>
        </div>
  </header>

    <main id="content" role="main">
      
      <div className="d-md-flex bg-img-hero" style={background_image}>

        <div className="container d-md-flex align-items-md-center justify-content-center height-md-100vh position-relative space-top-4 space-top-md-3 space-top-lg-2 space-top-xl-2 space-bottom-3  space-bottom-md-0">
          <div className="w-lg-65 mb-5">
            <div className="mb-5">
              <h1 className="display-4 text-white">Let's find your next <strong className="font-weight-semi-bold">home</strong></h1>
            </div>
            <LocationForm></LocationForm>
          </div>
        </div>
      </div>
      

      
      <div className="container space-2 space-md-3">
        
        <div className="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
          <figure id="intro-pic-nuance" className="svg-preloader-old  ie-height-72 max-width-12 mx-auto mb-3 col-lg-5">
            <img className="js-svg-injector intro-pic-nuance" src={require("../assets/svg/flat-icons/easy-payment.svg")} alt="SVG"
                data-parent="#intro-pic-nuance"/>
          </figure>
          <h2 className="font-weight-medium">We use machine learning to understanding every nuance to a neigborhood and to get the right house for you... all before visiting the property</h2>
        </div>
        

        <div className="row align-items-lg-center">
          <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
            <ul className="list-unstyled">
              
              <li className="u-indicator-steps py-3">
                <div className="media align-items-center border rounded p-5">
                  <div className="d-flex u-indicator-steps__inner mr-3">
                    <span className="display-4 text-primary font-weight-medium">1.</span>
                  </div>
                  <div className="media-body">
                    <p className="mb-0">Answer a few questions to tell us about yourself</p>
                  </div>
                </div>
              </li>
              

              
              <li className="u-indicator-steps py-3">
                <div className="media align-items-center border rounded p-5">
                  <div className="d-flex u-indicator-steps__inner mr-3">
                    <span className="display-4 text-primary font-weight-medium">2.</span>
                  </div>
                  <div className="media-body">
                    <p className="mb-0">Our assitant will create a personalized list of houses that appeals to you</p>
                  </div>
                </div>
              </li>
              

              
              <li className="u-indicator-steps py-3">
                <div className="media align-items-center border rounded p-5">
                  <div className="d-flex u-indicator-steps__inner mr-3">
                    <span className="display-4 text-primary font-weight-medium">3.</span>
                  </div>
                  <div className="media-body">
                    <p className="mb-0">Browse and give feedback as you go to get real time updated suggestions</p>
                  </div>
                </div>
              </li>
              
            </ul>
          </div>
          <div id="SVGhouseAgency" className="col-lg-6 order-lg-1 svg-preloader-old">
            <div className="pr-lg-7">
              <figure className="ie-house-agency">
                <img className="js-svg-injector" src={require("../assets/svg/flat-icons/for-sale.svg")} alt="Image Description"
                    data-parent="#SVGhouseAgency"/>
              </figure>
            </div>
          </div>
        </div>
      
        
      </div>
      

      
      <div className="container">
        <hr className="my-0"/>
      </div>
      

      
      {/* <div className="container space-2 space-md-3">
        <div className="row justify-content-md-between">
          <div className="col-sm-6 offset-sm-3 col-lg-4 offset-lg-0 mb-9 mb-lg-0">
            
            <div className="text-center px-lg-5">
              <figure id="icon12" className="svg-preloader-old ie-height-72 max-width-10 mx-auto mb-3">
                <img className="js-svg-injector" src={require("../assets/svg/icons/icon-12.svg")} alt="SVG"
                    data-parent="#icon12"/>
              </figure>
              <h3 className="h5">Property guides</h3>
              <p>A must-have read for anyone buying or selling property.</p>
              <a className="btn btn-sm btn-soft-primary transition-3d-hover" href="#">
                Learn More
                <span className="fas fa-angle-right ml-2"></span>
              </a>
            </div>
            
          </div>

          <div className="col-sm-6 col-lg-4 mb-9 mb-lg-0">
            
            <div className="text-center px-lg-5">
              <figure id="icon10" className="svg-preloader-old ie-height-72 max-width-10 mx-auto mb-3">
                <img className="js-svg-injector" src={require("../assets/svg/icons/icon-10.svg")} alt="SVG"
                    data-parent="#icon10"/>
              </figure>
              <h3 className="h5">Affordability calculator</h3>
              <p>Find out how much you could borrow.</p>
              <a className="btn btn-sm btn-soft-primary transition-3d-hover" href="#">
                Check Now
                <span className="fas fa-angle-right ml-2"></span>
              </a>
            </div>
            
          </div>

          <div className="col-sm-6 col-lg-4">
            
            <div className="text-center px-lg-5">
              <figure id="icon11" className="svg-preloader-old ie-height-72 max-width-10 mx-auto mb-3">
                <img className="js-svg-injector" src={require("../assets/svg/icons/icon-11.svg")} alt="SVG"
                    data-parent="#icon11"/>
              </figure>
              <h3 className="h5">Mortgage calculator</h3>
              <p>Calculate monthly mortgage payments.</p>
              <a className="btn btn-sm btn-soft-primary transition-3d-hover" href="#">
                Check Now
                <span className="fas fa-angle-right ml-2"></span>
              </a>
            </div>
            
          </div>
        </div>
      </div>
       */}

      
      <div className="bg-light">
        <div className="container space-2 space-md-3">
          
          <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
            <figure id="icon13" className="svg-preloader-old ie-height-72 max-width-10 mx-auto mb-3">
              <img className="js-svg-injector" src={require("../assets/svg/icons/icon-13.svg")} alt="SVG"
                  data-parent="#icon13"/>
            </figure>
            <h2 className="font-weight-medium">Find the latest homes for sale, waiting for you in your inbox</h2>
          </div>
          


          <div className="text-center">
            <a className="btn btn-sm btn-soft-primary btn-wide transition-3d-hover" href="/results">View interactive map</a>
          </div>
        </div>
      </div>
      

      
      <div className="position-relative space-2 space-md-3 space-lg-4">
        
        <div id="SVGlaptopAndIphone" className="col-lg-6 svg-preloader-old position-lg-absolute top-lg-0 left-lg-0 content-centered-y--lg mb-9 mb-lg-0">
          <figure className="ie-laptop-and-iphone">
            <img className="js-svg-injector" src={require("../assets/svg/components/laptop-and-iphone.svg")} alt="Image Description"
                data-img-paths='[
                  {"targetId": "#SVGlaptopAndIphoneImg1", "newPath": "../../assets/img/1618x1010/img2.jpg"},
                  {"targetId": "#SVGlaptopAndIphoneImg2", "newPath": "../../assets/img/282x500/img8.jpg"}
                ]'
                data-parent="#SVGlaptopAndIphone"/>
          </figure>
        </div>
        

        <div className="container">
          <div className="row justify-content-lg-end">
            <div className="col-lg-5">
              
              <div className="pl-lg-4">
                <div className="mb-5">
                  <h2 className="font-weight-medium">Bring your real estate assitant with you everywhere</h2>
                  <p>Coming soon</p>
                </div>

                
                <button type="button" className="btn btn-xs btn-dark btn-wide transition-3d-hover text-left mb-2 mr-sm-1" disabled>
                  <span className="media align-items-center">
                    <span className="fab fa-apple font-size-2 mr-3"></span>
                    <span className="media-body">
                      <span className="d-block">Download on the</span>
                      <strong className="font-size-1">App Store</strong>
                    </span>
                  </span>
                </button>
                

                
                <button type="button" className="btn btn-xs btn-dark btn-wide transition-3d-hover text-left mb-2" disabled>
                  <span className="media align-items-center">
                    <span className="fab fa-google-play font-size-2 mr-3"></span>
                    <span className="media-body">
                      <span className="d-block ">Get it on</span>
                      <strong className="font-size-1">Google Play</strong>
                    </span>
                  </span>
                </button>
                
              </div>
              
            </div>
          </div>
        </div>
      </div>
      

      
      <div className="bg-light">
        <div className="container text-center space-2 space-md-3">
        
        <div className="w-md-60 text-center mx-auto mb-6">
          <h2 className="font-weight-medium">Join us in making home buying <span className="text-primary font-weight-semi-bold">painless</span></h2>
        </div>
        

        <div className="mb-6">
          <a className="btn btn-primary btn-wide transition-3d-hover" href="#">Get Started Now</a>
        </div>

{/*         
        <div className="d-flex justify-content-center">
          <a className="js-fancybox media align-items-center u-media-player" href="javascript:;"
            data-src="//vimeo.com/167434033"
            data-speed="700"
            data-animate-in="zoomIn"
            data-animate-out="zoomOut"
            data-caption="Stroll.ai">
            <span className="u-media-player__icon u-media-player__icon--primary mr-3">
              <span className="fas fa-play u-media-player__icon-inner"></span>
            </span>
            <span className="media-body">
              Watch video
            </span>
          </a>
        </div> */}
        
      </div>
      </div>
    </main>


  <footer className="container text-center space-2">
  <a className="d-inline-flex align-items-center mb-3" href="index.html" aria-label="Stroll.ai">
    <img src={logoFav} width="36px" height="36px" style={{marginBottom: 0}} ></img>
    <span className="brand brand-primary">Stroll.AI</span>
  </a>

  <ul className="list-inline list-group-flush list-group-borderless">
    <li className="list-inline-item px-2">
      <a className="list-group-item-action" href="../pages/about-agency.html">About</a>
    </li>
    <li className="list-inline-item px-2">
      <a className="list-group-item-action" href="../pages/services-agency.html">Services</a>
    </li>
    <li className="list-inline-item px-2">
      <a className="list-group-item-action" href="../pages/careers-listing.html">Careers</a>
    </li>
    <li className="list-inline-item px-2">
      <a className="list-group-item-action" href="../blog/classic-right-sidebar.html">Blog</a>
    </li>
  </ul>

  <p className="small text-muted mb-0">© Stroll.ai 2019</p>
  </footer>
  {/*   
  <footer className="border-top">
    <div className="container">
      <div className="border-bottom">
        <div className="row justify-content-lg-between space-2 space-top-md-3">
  
          <div className="col-md-7 col-lg-5">
            <div className="mb-4">
              <h4 className="h6">Get latest news in your box!</h4>
              <p className="font-size-1">Get special offers and deals. Never spam.</p>
            </div>

            
            <form className="js-validate js-form-message">
              <label className="sr-only" htmlFor="subscribeSrEmail">Your email address</label>
              <div className="input-group">
                <input type="email" className="form-control" name="email" id="subscribeSrEmail" placeholder="Your email address" aria-label="Your email address" aria-describedby="subscribeButton" required
                      data-msg="Please enter a valid email address."
                      data-error-className="u-has-error"
                      data-success-className="u-has-success"/>
                <div className="input-group-append">
                  <button className="btn btn-primary" type="submit" id="subscribeButton">Subscribe</button>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>

      <div className="text-center py-7">
        
        <p className="small text-muted mb-0">&copy; Stroll.AI 2019</p>
        
      </div>
    </div>
  </footer>
  */}


  <a className="js-go-to u-go-to" href="#"
    data-position='{"bottom": 15, "right": 15 }'
    data-type="fixed"
    data-offset-top="400"
    data-compensation="#header"
    data-show-effect="slideInUp"
    data-hide-effect="slideOutDown">
    <span className="fas fa-arrow-up u-go-to__inner"></span>
  </a>

    </div>

  );
  }
}


export default Landing;