import React, { Component } from 'react';
import "../../assets/vendor/ion-rangeslider/css/ion.rangeSlider.css";
class InterestsThin extends Component {

    constructor(props) {

        super(props);
        if (props.interests)
            this.state = props.interests
        else
            this.state = {
                parks: { text: "Nice to have", value: 1 },
                restaurants: { text: "Nice to have", value: 1 },
                nightlife: { text: "Nice to have", value: 1 },
                walking: { text: "Nice to have", value: 1 },
                kid: { text: "Nice to have", value: 1 },
                quiet: { text: "Nice to have", value: 1 },
            };
    }
    componentDidMount() {
        window.$.HSCore.components.HSRangeSlider.init('.js-range-slider')
        let interestsObj = this;
        for (let item of window.$(".js-range-slider")) {
            let $item = window.$(item);

            //check to see if we need to update the control with passed in props values
            let sliderFor = $item.attr("data-sliderfor");
            if (true || sliderFor in this.state) {
                $item.data("ionRangeSlider").update({ from: this.state[sliderFor].value });
            }


            //subscribe to each slider's jquery onChange event to update the state
            $item.on("change", function () {
                let $inp = window.$(this);
                let text = $inp.prop("value"); // reading input value
                let val = $inp.data("from"); // reading input data-from attribute
                let sliderFor = $inp.attr("data-sliderfor"); // reading input data-from attribute
                let stateUpdate = {};
                stateUpdate[sliderFor] = { text: text, value: val };
                interestsObj.setState(stateUpdate);
            })
        }

        if (this.props.onChange)
            this.props.onChange(this.state);

    }

    componentWillUnmount() {
        for (let item of window.$(".js-range-slider")) {
            window.$(item).off("change");
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state)
            return;

        if (this.props.onChange)
            this.props.onChange(this.state);
    }
    render() {
        return (
            <div className="px-2">
                <div className="d-flex flex-wrap justify-content-between py-2 py-md-0">
                    <div className="list-group-item flex-column align-items-start col-lg-3 m-lg-3 col-sm-6 m-sm-1">
                        <ThinSlider property={"parks"} Title={"Parks"}></ThinSlider>
                    </div>
                    <div className="list-group-item flex-column align-items-start col-lg-3 m-lg-3 col-sm-6 m-sm-1">
                        <ThinSlider property={"restaurants"} Title={"Restaurants"}></ThinSlider>
                    </div>
                    <div className="list-group-item flex-column align-items-start col-lg-3 m-lg-3 col-sm-6 m-sm-1">
                        <ThinSlider property={"nightlife"} Title={"Nightlife"}></ThinSlider>
                    </div>
                    <div className="list-group-item flex-column align-items-start col-lg-3 m-lg-3 col-sm-6 m-sm-1">
                        <ThinSlider property={"walking"} Title={"Walking"}></ThinSlider>
                    </div>
                    <div className="list-group-item flex-column align-items-start col-lg-3 m-lg-3 col-sm-6 m-sm-1">
                        <ThinSlider property={"kid"} Title={"Kid Friendly"}></ThinSlider>
                    </div>
                    <div className="list-group-item flex-column align-items-start col-lg-3 m-lg-3 col-sm-6 m-sm-1">
                        <ThinSlider property={"quiet"} Title={"Quiet"}></ThinSlider>
                    </div>
                </div>
            </div>
        );
    }
}

const ThinSlider = (props) => {
    return (
        <div class="row  align-items-center">
            <div className="col-6">
                <h6 className="mb-1 text-left">{props.Title}</h6>
            </div>
            <div class="col-9">
                <input className="js-range-slider " type="text"
                    data-extra-classes="u-range-slider u-range-slider-indicator"
                    data-type="single"
                    data-hide-from-to="false"
                    data-force-edges="true"
                    data-from="1"
                    data-sliderfor={props.property}
                    data-values={['Not Important', 'Nice to have', 'Very Important', 'Essential']} />
            </div>
        </div>
    );
}

export default InterestsThin;